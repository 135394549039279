@font-face {
  font-family: "Inter";
  src: url("./Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
